export select_size=(object) ->
  option = $("option:selected", object)
  $("#product_price_"+option.data('productId')+" .price").html(option.data("price")+"")
  $("#add_to_basket_"+option.data('productId')).data("id",object.value)
export add_to_basket=(object) ->
  $.ajax
    url: '/'+$(object).data("type")+'s/'+$(object).data("id")+'/basket'
    type: 'post'
    dataType: 'json'
    success: (data) ->
      document.getElementById("basket_count").innerHTML = data.count
      not_blank = document.querySelectorAll('.if-not-blank')
      el.style.visibility = 'visible' for el in not_blank
    error: (data) -> {}
export remove_from_basket=(object) ->
  $.ajax
    url: '/'+$(object).data("type")+'s/'+$(object).data("id")+'/basket'
    type: 'delete'
    dataType: 'json'
    success: (data) ->
      document.getElementById("basket_count").innerHTML = data.count
      $("#basket_products").html(data.html)
      if data.count == 0
        not_blank = document.querySelectorAll('.if-not-blank')
        el.style.visibility = 'hidden' for el in not_blank
      $.ajax
        url: '/orders/basket'
        type: 'GET'
        dataType: 'json'
        success: (sub_data)->
          $("#basket_orders .row.products").html( sub_data.html)
    error: (data) -> {}
export show_basket =() ->
  $.ajax
    url: '/orders/basket'
    type: 'GET'
    dataType: 'json'
    success: (data)->
      $("#basket_orders .row.products").html( data.html)
      not_blank = document.querySelectorAll('.if-not-blank')
      el.style.visibility = 'visible' for el in not_blank
export sort=(object)->
  $.ajax
    url: $(location).attr("href")
    type: 'get'
    dataType: 'json'
    data:
      sort: $(object).val()
      page: $("#page").data("page")
    success: (data)->
      $("#products").html(data.html)
    error: (data) ->
export change_count=(object)->
  sum = $(object).val()*$("#order_product_"+$(object).data("id")+"_price").text()
  $("#order_product_"+$(object).data("id")+"_sum_price").html(sum)
  all_sum = 0
  $(".all_sum").each ->
    all_sum += parseFloat($(this).text())
  $("#order_sum_price").html(all_sum)
export page=(object)->
  $.ajax
    url: $(location).attr("href")
    type: 'get'
    dataType: 'json'
    data:
      sort: $("#sort").val()
      page: $(object).data("page")
    success: (data)->
      $("#products").html(data.html)
      $("#pagination").html(data.pagination_html)
      $('html, body').animate { scrollTop: $('#products').offset().top }, 1500
    error: (data) ->
export delivery_method=(object)->
  if($(object).val()=="Самовывоз")
    $(".if_not_self-removal").hide()
    $(".if_self-removal").show()
  else
    $(".if_not_self-removal").show()
    $(".if_self-removal").hide()
export payment_method=(object)->
  if($(object).val()=="На карточку")
    $(".if_pay-to-card").show()
  else
    $(".if_pay-to-card").hide()
export click_to_card=(object)->
  $(object).select()
  document.execCommand("copy")
  alert("Скопійовано: " + $(object).val())

$(document).on 'turbolinks:load' ,->
  # console.log("Hellow coffee")
  # element = document.getElementById('user_phone')
  # if element != null
  #   maskOptions = mask: '+{38}(000)000-00-00'
  #   mask = IMask(element, maskOptions)
  $ ->
    $('[data-toggle="tooltip"]').tooltip()
  $ ->
    $('[data-toggle="popover"]').popover()
  $ ->
    $('[data-toggle="collapse"]').collapse()
  $('.categories').on 'click', 'li', ->
    if $(this).hasClass('load')
      category_open($(this).data("id"))
