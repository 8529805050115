import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import '@client-side-validations/client-side-validations'
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'

import "bootstrap"
// import "@fortawesome/fontawesome-free/js/all.min";
// import "@fortawesome/fontawesome-free/js/brands.min";
// import "@fortawesome/fontawesome-free/js/conflict-detection.min";
import "@fortawesome/fontawesome-free/js/fontawesome.min";
// import "@fortawesome/fontawesome-free/js/regular.min";
import "@fortawesome/fontawesome-free/js/solid.min";
// import "@fortawesome/fontawesome-free/js/v4-shims.min";



import "../stylesheets/custom"

import 'packs/custom'
import {
  select_size,
  add_to_basket,
  remove_from_basket,
  sort,
  show_basket,
  change_count,
  page,
  delivery_method,
  payment_method,
  click_to_card
} from './custom'
window.select_size = select_size
window.add_to_basket = add_to_basket
window.remove_from_basket = remove_from_basket
window.sort = sort
window.show_basket = show_basket
window.change_count = change_count
window.page = page
window.delivery_method = delivery_method
window.payment_method = payment_method
window.click_to_card = click_to_card

document.addEventListener("turbolinks:load", function() {
  if (!parseInt(document.getElementById("basket_count").innerHTML)>0 ) {
    for (let el of document.querySelectorAll('.if-not-blank'))
      el.style.visibility = 'hidden';
  }
})
